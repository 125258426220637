import {Component, inject, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatTableModule} from '@angular/material/table';
import {saveAs} from 'file-saver';
import {BaseListComponent, BaseListPaginatorComponent} from '../../common';
import {Company, CompanySite, HasIdName, ReportArchive} from '../../model';
import {extractFilenameFromHeader} from '../../util';
import {ReportService} from '../../common/service/report.service';
import {RouterModule} from '@angular/router';
import {Dialog} from '../../common/dialog.service';
import {MatChipsModule} from '@angular/material/chips';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {FormBuilder, ReactiveFormsModule} from '@angular/forms';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatCardModule} from '@angular/material/card';

type Filter = { search?: string | null, chain?: string | null };

@Component({
  selector: 'app-archive',
  templateUrl: 'archive.component.html',
  styleUrls: ['archive.component.scss'],
  providers: [{provide: BaseListComponent, useExisting: ArchiveComponent}],
  imports: [
    CommonModule, ReactiveFormsModule, RouterModule, MatTableModule, MatPaginatorModule, MatChipsModule,
    MatButtonModule, MatIconModule, MatCardModule, MatFormFieldModule, MatInputModule, BaseListPaginatorComponent
  ]
})
export class ArchiveComponent extends BaseListComponent<ReportArchive, Filter> implements OnInit {
  columns = ['date', 'name', 'edit'];
  company!: Company;
  site!: CompanySite;
  private readonly service = inject(ReportService);
  private readonly dialog = inject(Dialog);
  form = new FormBuilder().group({
    search: [''],
    chain: [null as HasIdName | null]
  });
  chains: HasIdName[] = [];

  override ngOnInit() {
    super.ngOnInit();
    this.form.valueChanges
      .subscribe(async ({search, chain}) => {
        this.filter = {...this.filter, search, chain: chain?.id,};
        await this.search();
      });

    this.route.data.subscribe(({chains}) => {
      this.chains = chains || [];
      this.route.queryParams.subscribe(async (params) => {
        this.form.patchValue({
          search: params['search'],
          chain: this.chains.find(c => c.id === params['chain']),
        }, {emitEvent: false});
      });

      this.route.data.subscribe(async ({companySite, openFirstDocument}) => {
        this.company = companySite?.company;
        this.site = companySite?.site;
        if (openFirstDocument) {
          await this.preview(this.list[0]);
        }
      });
    });
  }

  async downloadPdf(archive: ReportArchive) {
    const {body, headers} = await this.service.downloadArchivePdf(archive);
    saveAs((body)!!, extractFilenameFromHeader(headers.get('Content-Disposition')!!));
  }

  async downloadZip(archive: ReportArchive) {
    const {body, headers} = await this.service.downloadArchiveZip(archive);
    saveAs((body)!!, extractFilenameFromHeader(headers.get('Content-Disposition')!!));
  }

  async preview(archive: ReportArchive) {
    const {body} = await this.service.downloadArchivePdf(archive);
    if (body) {
      await this.dialog.showPdf(`Report: ${archive.name} / ${archive.product} / ${archive.norm.name}`, body);
    }
  }
}
