<h1>Report Archive</h1>

<div style="margin-bottom:1em" *ngIf="company && site">
  Please find your archived Compliance Reports below. To create a new report, start by building your
  <button mat-stroked-button class="inline-button" [routerLink]="['/company',company.id,'site',site.id,'chain']">
    Supply Chain
    <mat-icon>arrow_outward</mat-icon>
  </button>
  or continue with an existing review
  <button mat-stroked-button class="inline-button" [routerLink]="['/company',company.id,'site',site.id,'evaluation']">
    Active reviews
    <mat-icon>arrow_outward</mat-icon>
  </button>
</div>

<form class="filter" [formGroup]="form" *ngIf="company && site">
  <div>
    <mat-chip-row *ngIf="form.value.chain" (removed)="form.controls.chain.reset()">
      {{ form.value.chain.name }}
      <button matChipRemove>
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip-row>
  </div>
  <mat-form-field appearance="fill">
    <mat-label>Search</mat-label>
    <input formControlName="search" matInput>
    <button mat-icon-button matSuffix>
      <mat-icon>search</mat-icon>
    </button>
  </mat-form-field>
</form>

<mat-table [dataSource]="list">
  <ng-container matColumnDef="name">
    <mat-cell *matCellDef="let e">
      <div class="block">
        <div style="font-weight: bold">{{ e.name }}</div>
        <div>{{ e.product }}</div>
        <div style="font-style: italic">{{ e.norm.name }}</div>
        <div style="color:#888">Date: {{ e._created | date:'yyyy-MM-dd' }}</div>
      </div>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="edit">
    <mat-header-cell *matHeaderCellDef></mat-header-cell>
    <mat-cell *matCellDef="let e">
      <button (click)="$event.stopPropagation(); downloadPdf(e)" mat-raised-button color="primary"
              title="Download PDF Report">
        <mat-icon>description</mat-icon>
        PDF
      </button>
      <button (click)="$event.stopPropagation(); downloadZip(e)" mat-raised-button color="primary"
              title="Download ZIP-Archive containing all Documents and GeoJSON">
        <mat-icon>folder</mat-icon>
        ZIP
      </button>
    </mat-cell>
  </ng-container>
  <mat-row *matRowDef="let row; columns: ['name', 'edit']; let e" (click)="preview(e)"></mat-row>
</mat-table>

<app-paginator/>
